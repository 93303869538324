import { PageNotFound } from ":src/components/route";
import Layout from "./layout";
import { Route, Switch } from "wouter";
import { WordCloud } from ":src/modules/wordcloud";
import { Poll } from ":src/modules/poll";
import { OpenQuestions } from ":src/modules/open-questions";
import { QRCodeBar } from ":src/modules/qr-code-bar";

export default function MonitorRouter() {
  return (
    <Layout sidebar={<QRCodeBar />}>
      <Switch>
        <Route
          path="/wordCloud/:eventId"
          children={<WordCloud displayOnly />}
        />
        <Route path="/poll/:eventId" children={<Poll displayOnly />} />
        <Route
          path="/open-question/:id"
          children={<OpenQuestions displayOnly />}
        />
        <Route children={<PageNotFound />} />
      </Switch>
    </Layout>
  );
}
